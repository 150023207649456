<template>
  <spinner-component v-if="loader" type="Table"></spinner-component>
  <q-card-actions class="filterhead table-card-actions" v-if="!loader && bom_name!=null">
    <q-btn round dense flat color="primary" icon="more_vertical" disable class="round-icon-btn" />
    <q-input v-if="isSearchOpen" v-model="search" label="Search" outlined clearable dense>
      <template v-slot:append>
        <q-btn flat dense color="primary" size="sm" icon="clear" @click="closeSearch" />
      </template>
    </q-input>
    <q-btn round class="round-icon-btn" dense flat color="primary" icon="search" @click="toggleSearch" disable />
    <!-- <q-space />
    <q-breadcrumbs align="center" style="font-weight: 600;" class="qsummarybread">
      <q-breadcrumbs-el :label="formattedString(titledata[0])" icon="factory">
        <q-tooltip anchor="bottom middle" self="top middle" :offset="[-1, -1]">
          {{ myStore.NOEM }}
        </q-tooltip>
      </q-breadcrumbs-el>
      <q-breadcrumbs-el :label="formattedString(titledata[1])" icon="book"> <q-tooltip anchor="bottom middle"
          self="top middle" :offset="[-1, -1]">
          {{ myStore.NProduct.product }}
        </q-tooltip></q-breadcrumbs-el>
      <q-breadcrumbs-el  @click="showTree()" :label="formattedString(titledata[2])" icon="login" class="text-info cursor-pointer"> <q-tooltip anchor="bottom middle"
          self="top middle" :offset="[-1, -1]">
          {{ myStore.NIter.iteration }}
        </q-tooltip></q-breadcrumbs-el>
    </q-breadcrumbs> -->

    <q-space />
    <q-btn size="md" outline dense :label="isEditing ? 'Cancel' : 'Edit'" color="primary" @click="SaveClose"
      class=" custom-button" :icon="isEditing ? 'close' : 'edit'" :disable="(lifecycle_status == 'New')|| (myStore.userdata.role=='buyer'&& myStore.partListStatus==false || myStore.userdata.role=='quoter'&& myStore.partListStatus==true) ? true : false "></q-btn>

    <q-btn size="md" outline dense label="Submit" color="primary " class="custom-button" @click="MoveNextTab()" icon="save"
      v-if="!isEditing" :disable="(lifecycle_status == 'New')|| (myStore.userdata.role=='buyer'&& myStore.partListStatus==false || myStore.userdata.role=='quoter'&& myStore.partListStatus==true) ? true : false "></q-btn>

    <q-btn size="md" outline dense label="Submit" @click="UpdateRfqTable()" color="primary" icon="save"
      :disable="isEditing ? false : true" v-if="isEditing" class="custom-button"></q-btn>

  </q-card-actions>


  <q-markup-table v-if="!loader"  class="rfq-markup-table">
    <tbody v-if="rfqSchema" :class="{ 'column-up': myStore.isToastVisible == false, 'rfq-table': myStore.isToastVisible == true }">
      <tr>
        <td class=" rfqLabel">Quantity</td>
        <td class="rfq-td" v-for="(qtyVal, index) in  isPartList == true ? newArray.slice(0, 1):newArray.slice(0, 4)" :key="index">
          <!-- <q-input class="q-mr-sm" v-if="!isEditing" filled v-model="qtyVal.value" disable dense>  </q-input>-->
          <div v-if="!isEditing">
            <div v-if="newArray.length> 0 && newArray[0].value == ' ' || newArray[0].value == '-' || newArray == 'null'">
              <q-btn color="primary" sixe="xs" dense style="margin-left: 25%;">
               {{myStore.partListStatus==true? `No Data Found. Upload ${myStore.treeLabels[3]} or Extract the ${myStore.treeLabels[3]} `:`No Data Found. Upload ${myStore.treeLabels[4]}  or Extract the ${myStore.treeLabels[4]} `}}
                <q-badge color="secondary" floating dense size="xs"><q-icon name="report"></q-icon></q-badge>
              </q-btn>
            </div>
            <div v-else>
              <q-icon class="q-mb-xs" name="star" v-if="qtyVal.is_primary" color="secondary"></q-icon>
              {{ qtyVal.value }}
            </div>


          </div>

          <q-input class="q-mr-xs" v-else size="sm" filled v-model="qtyVal.value" dense>

            <q-btn v-model="qtyVal.is_primary" flat dense :icon="qtyVal.is_primary ? 'star' : 'star_border'"
              @click="setAsprimary(index)"></q-btn>
            <q-btn flat dense v-if="qtyVal.is_primary == false" @click="removeArray(index)"> <q-icon flat name="delete"
                color="negative" class=""></q-icon></q-btn>

          </q-input>
        </td>
        <td class="rfq-td" v-if="isEditing && newArray.length < 4 && newArray.length>0 && isPartList==false">
          <q-btn size="md" flat class="q-mx-md " color="secondary" @click="AddRow(newArray)" icon="fa fa-circle-plus ">
          </q-btn>
        </td>
      </tr>
      <tr>
        <td class="bg-primary text-white rfqLabel">Lead Time(weeks)</td>
        <td class="rfq-td" colspan="4">
          <div v-if="!isEditing">
            <div v-if="newArray.length>0&&newArray[0].value == ' ' || newArray[0].value == '-'" >{{ " " }}</div>
            <div v-else>{{ lead_time }}</div>
          </div>
          <q-input v-else filled v-model="lead_time" dense type="number" min="0"/>
        </td>

      </tr>
      <tr>
        <td class="bg-primary text-white rfqLabel">Uploaded File Name</td>
        <td class="rfq-td" colspan="4">
          <div v-if="!isEditing">
            <div v-if="newArray.length>0&&newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
            <div v-else>{{ file_name }}</div>
          </div>
          <div v-else style="cursor: not-allowed;">{{ file_name }}</div>
        </td>

      </tr>
      <tr>
        <td class="bg-primary text-white rfqLabel">Last Updated In</td>
        <td class="rfq-td" colspan="4">
          <div v-if="newArray.length>0&&newArray[0].value == ' ' || newArray[0].value == '-'" style="cursor: not-allowed;">{{ ' ' }}</div>
          <div v-else style="cursor: not-allowed;">{{ iteration_name }}</div>
        </td>

      </tr>
      <tr v-for="(cols, index) in rfqSchema.properties" :key="index">
        <td class="bg-primary text-white rfqLabel">
          <!-- {{ $filters.formatFirstLetter(index) }} -->
          {{ index.charAt(0).toUpperCase() + index.slice(1).replace(/([A-Z])/g, ' $1') }}
        </td>
        <td class="bg-purple=11" colspan="4">
          <div v-if="index === 'testProcess'">
            <div v-if="!isEditing">
              <div v-if="newArray.length>0&&newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
              <div v-else>{{ rfqSchema.properties.testProcess }}</div>
            </div>
            <q-input v-else filled v-model="rfqSchema.properties.testProcess" dense />

          </div>

          <div v-if="index === 'comments'">
            <div v-if="!isEditing">
              <div v-if="newArray.length>0&&newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
              <div v-else>{{ rfqSchema.properties.comments.comment }}</div>
            </div>

            <div v-else>
              <q-input filled v-model="rfqSchema.properties.comments.comment" dense />
            </div>


          </div>
          <div v-if="index === 'cust Info'">
            <div v-if="!isEditing">{{ cols.value }}</div>
            <q-input v-else filled v-model="cols.value" dense />


          </div>
          <div v-if="index === 'dueDate'">
            <div v-if="!isEditing">
              <div v-if="newArray.length>0 && newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
              <div v-else>{{ ddate }}</div>
            </div>

            <q-input v-else filled v-model="ddate" dense readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-banner class="q-pa-none">
                      <q-date v-model="ddate" mask="YYYY-MM-DD">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                      </q-date>
                    </q-banner>
                  </q-popup-proxy>

                </q-icon>
              </template>
            </q-input>
          </div>


        </td>

      </tr>
      <tr class="rfq-footer" v-if="isPartList==false">
        <td class="bg-primary text-white rfqLabel">{{ this.myStore.treeLabels[0] }} Order Status</td>
        <td colspan="4">
          <q-select v-if="isEditing" filled v-model="OEMStatus" dense :disable="lifecycle_status !== 'Quoted'"
            :options="options" options-dense />
          <div v-else style="cursor: not-allowed;">
            <div v-if="newArray.length>0&& newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
            <div v-else> {{ OEMStatus }}</div>
          </div>
        </td>

      </tr>
      <tr class="rfq-footer">
        <td class="bg-primary text-white rfqLabel">Order Confirmation</td>
        <td colspan="4">
          <div v-if="!isEditing">
            <div v-if="newArray.length>0&& newArray[0].value == ' ' || newArray[0].value == '-'">{{ " " }}</div>
            <div v-else>{{ OrderConfirm }}</div>
          </div>
          <div v-else>
            <q-input filled v-model="orderConfirmation.order_id" dense label="Order Id" />
            <q-input filled v-model="orderConfirmation.quantity" dense label="Quantity" />
            <q-input filled v-model="orderConfirmation.date" dense label="Order Date" readonly>
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-banner class="q-pa-none">
                      <q-date v-model="orderConfirmation.date" mask="YYYY-MM-DD">
                        <div class="row items-center justify-end">
                          <q-btn v-close-popup label="Close" color="secondary" flat />
                        </div>
                      </q-date>
                    </q-banner>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
          </div>
        </td>

      </tr>
    </tbody>


  </q-markup-table>

  <q-dialog v-model="confirm">
    <q-card class="gdialog card-outline-primary  confirm-popup">

      <q-card-section  class="card-header-section1">
        <div class="text-h6 q-ml-sm"><q-icon name="report_problem" flat dense
              color="warning" size="25px" class="q-mb-xs"></q-icon><span class="q-ml-sm ">Update {{myStore.partListStatus==false?this.myStore.treeLabels[2]: this.myStore.treeLabels[3]}}  Confirmation</span></div>
      </q-card-section>

      <q-card-section class="">
        <span class=""> Are you sure you want to update {{myStore.partListStatus==false?this.myStore.treeLabels[2]: this.myStore.treeLabels[3]}} {{ bom_name }} ?</span>
      </q-card-section>
      <q-card-actions  align="right" class="card-footer-section">
        <q-btn :loading="loading" label="Ok" outline color="secondary" @click="UpdateRfqconfirm()">
          <template v-slot:loading>
            <q-spinner-facebook />
          </template>
        </q-btn>
        <q-btn label="Cancel" outline color="secondary" v-close-popup @click="loading = false" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
import { QuotesService, AlertService } from '@/services';
import { SpinnerComponent, commonFunction } from '@/utils';
import { defineComponent, ref } from 'vue';
import moment from 'moment'
import { useMyStore } from '@/store';

export default defineComponent({
  name: 'app',
  components: {
    SpinnerComponent
  },
  props: ['bom_name', 'AlifeCycle'],


  setup() {
    const myStore = useMyStore();
    return {
      myStore,
      isEditing: ref(false),
      loading: ref(false),
      loader: ref(true),
      isPartList:ref(false),
      rfqSchema: ref({ properties: { testProcess: "", comments: { comment_date: '-', comment: ' ' } } }),
      rfqUischema: ref({}),
      personalDetails: ref({ name: '', email: '', location: '' }),
      lead_time: ref(''),
      file_name: ref(''),
      iteration_name: ref(''),
      lifecycle_status: ref('In Processing'),
      orderConfirmation: ref({}),
      rlifeStatus: ref('In BOM Extract'),
      ddate: ref(''),
      OEMStatus: ref(''),
      cdate: ref(moment().format('YYYY-MM-DD')),
      confirm: ref(false),
      options: ref([
        'Processing', 'Won', 'Lost'
      ])
    };

  },
  watch: {

    bom_name(newBomName, oldBomName) {
      if (newBomName !== oldBomName) {
        this.getRfq();
        this.isEditing = false
      }
    },

    refreshpricing() {
      this.getRfq();
    }
  },
  computed: {
    OrderConfirm() {
      let order = this.orderConfirmation;
      var arr = []; var arr2 = "";
      Object.keys(order).forEach(function (key) {
        if (key == 'date') {
          if (order[key] !== null && order[key] !== undefined && order[key] !== "") {
            const formatDate = moment(order[key]).format('YYYY-MM-DD')
            arr.push(formatDate);
            arr2 += formatDate + "  ";
          } else {
            const formatDate = ""
            arr.push(formatDate);
            arr2 += formatDate + "   ";
          }
        }
        else {
          arr.push(order[key]);
          arr2 += order[key] + " ";
        }
      });
      return arr2;
    },
    refreshpricing() {
      return this.myStore.refreshpricing;
    },
    // rfqDetails() {
    //   return this.myStore.rfqDetails;
    // },
    newArray() {
      return this.myStore.newArray;
    },
    titledata() {
      var oemExpand = []
      if(this.bom_name!=null){
      oemExpand = JSON.parse(localStorage.getItem('expandedNodes'))
      oemExpand.push(this.bom_name);
      return oemExpand;
      }else{
        return ['','','']
      }
    },
  },

  methods: {
    // Format the header row Breadcrumbs
    formattedString(string) {
      const res = commonFunction.BreadcrumbFormat(string)
      return res
    },
    showTree(){
      this.myStore.isCardVisible=true;
      this.myStore.isToastVisible=true;
    },
    async getRfq() {
      // const bom = localStorage.getItem('bom_name')
      if(this.bom_name!=null){
      try {
        this.loader = true
        const WaitingSchema = await this.rfqSchema
        if (WaitingSchema) {
          const x = await QuotesService.getRfqDetails(this.bom_name)
          this.rfqDetails = x;
          if (x.required_quantity !== null && x.required_quantity !== undefined) {
            this.newArray = Object.keys(x.required_quantity).map(function (key) {
              return {
                value: key,
                is_primary: x.required_quantity[key].is_primary,
                details: x.required_quantity[key].details
              };
            });
            this.$emit("SelectQty", this.newArray);
          }
          else {
            this.newArray = [{ value: '-', is_primary: false, details: '-' }]
          }
          if (x.file_name !== null && x.file_name !== undefined) {
            this.file_name = x.file_name
          }
          else {
            this.file_name = ' '
          }
          this.ddate = x.due_date;
          if (this.ddate != null) {
            this.ddate = moment(x.due_date).utc().utcOffset("+05:30").format("YYYY-MM-DD")
          }

          this.lead_time = x.lead_time;
          this.iteration_name = x.iteration_name;
          this.lifecycle_status = x.lifecycle_status;
          this.OEMStatus = x.order_status;
          this.isPartList=x.is_part_list != null && x.is_part_list != '' ? x.is_part_list : false
          // this.rfqSchema.properties.dueDate = x.due_date;
          if (x.more_info !== null && x.more_info !== undefined) {
            this.personalDetails.name = x.more_info.name
            this.personalDetails.email = x.more_info.email
            this.personalDetails.location = x.more_info.location
            if (x.more_info.comments !== null && x.more_info.comments !== undefined) {
              this.rfqSchema.properties.comments.comment = x.more_info.comments.comment
              this.rfqSchema.properties.comments.comment_date = x.more_info.comments.comment_date
            }
            else {
              this.rfqSchema.properties.comments = { comment_date: " -", comment: " " }
            }
            if (x.more_info.test_process !== null && x.more_info.test_process !== undefined) {
              this.rfqSchema.properties.testProcess = x.more_info.test_process;
            }
            else {
              this.rfqSchema.properties.testProcess = ' '

            }
            if (x.more_info.order_confirmation !== null && x.more_info.order_confirmation !== undefined) {
              this.orderConfirmation = x.more_info.order_confirmation;
              if (x.more_info.order_confirmation.date !== null && x.more_info.order_confirmation.date !== undefined) {
                this.orderConfirmation.date = moment(x.more_info.order_confirmation.date).utc().utcOffset("+05:30").format("YYYY-MM-DD")
              } else {
                this.orderConfirmation.date = ""
              }
            }
            else {
              this.orderConfirmation = { "order_id": " ", "date": '', "quantity": "", "order_file_name": " " }

            }
          }
          else {
            this.rfqSchema.properties.comments = {
              comment_date: "-",
              comment: "  "
            },
              this.rfqSchema.properties.testProcess = " ",
              this.orderConfirmation = { "order_id": "", "date": "", "quantity": "", "order_file_name": "" }
          }
          this.loader = false;
        }
      }
      catch (err) {
        this.loader = false;
        this.rfqSchema.properties.testProcess = ' '
        this.lifecycle_status = "New"
        this.rfqDetails = [];
        console.error(err);
      }
    }else{
      this.loader=false
    }

    },
    MoveNextTab() {
      const ZeroValue = this.newArray.some(item => parseInt(item.value) === 0);
      if (ZeroValue) {
        AlertService.Error("value Error: Quantity must be Greater than 0")
      } else {
        if (this.lifecycle_status == 'New' || this.lifecycle_status == 'In RFQ') {
          QuotesService.UpdateLifeCycle(this.bom_name, this.rlifeStatus).then((x) => {
            console.log(x)
            // this.myStore.loadQuotes();
            setTimeout(() => {
              AlertService.Success(`${this.myStore.partListStatus==false?this.myStore.treeLabels[2]: this.myStore.treeLabels[3]} Submitted Successfully`);
            })
          }, "1000");
          this.$emit('updateRfqStep', 2, this.rlifeStatus)
        }
        else {
          this.$emit('updateRfqStep', 2)
        }
      }

    },
    async UpdateRfqTable() {

      // Show Quasar Dialog
      this.confirm = true;
    },
    UpdateRfqconfirm() {
      const ZeroValue = this.newArray.some(item => parseInt(item.value) === 0);
      if (ZeroValue) {
        this.confirm = false
        AlertService.Error("value Error: Quantity must be Greater than 0")
      }
      else {
        this.loading = true
        const hasPrimary = this.newArray.some(obj => obj.is_primary);
        const newObj = hasPrimary
          ? this.newArray.reduce((result, obj) => {
            result[obj.value] = {
              is_primary: obj.is_primary,
              details: obj.details
            };
            return result;
          }, {})
          : this.newArray.reduce((result, obj, index) => {
            result[parseInt(obj.value)] = {
              is_primary: index === 0,
              details: obj.details
            };
            return result;
          }, {})
        var comment = { comment_date: new Date(this.cdate).toISOString(), comment: this.rfqSchema.properties.comments.comment }
        var odate = " "
        if (this.orderConfirmation.date === null || this.orderConfirmation.date === '') {
          odate = null
        } else {
          odate = new Date(this.orderConfirmation.date).toISOString()
        }

        this.orderConfirmation = {
          "order_id": this.orderConfirmation.order_id,
          "date": odate,
          "quantity": this.orderConfirmation.quantity ? this.orderConfirmation.quantity : 0,
          "order_file_name": this.orderConfirmation.order_file_name
        }
        var email = this.personalDetails.email || null;
        if (email == "") { email = null; }
        var details = {
          name: this.personalDetails.name || '',
          email: email,
          location: this.personalDetails.location || '',

        }
        this.lead_time = this.lead_time ? this.lead_time : 0
        var date=''
        if(this.ddate !=null && this.ddate !=''){
           date= new Date(this.ddate).toISOString()
        }else{date=null}
        const body = {
          bom_name: this.bom_name,
          iteration_name: this.iteration_name,
          file_name: this.file_name,
          lead_time: this.lead_time,
          required_quantity: newObj,
          lifecycle_status: this.lifecycle_status,
          order_status: this.OEMStatus,
          due_date: date,
          is_part_list:this.isPartList,
          more_info: {
            name: details.name,
            email: details.email,
            location: details.location,
            comments: comment,
            order_confirmation: this.orderConfirmation,
            test_process: this.rfqSchema.properties.testProcess,
            // attachments: this.rfqSchema.properties.attachments
          }

        };
        QuotesService.updateRfq(this.bom_name, body).then((x) => {
          localStorage.setItem('qty', null)
          this.confirm = false;
          this.isEditing = false
          this.getRfq();
          this.myStore.loadQuotes();
          // localStorage.setItem('qty', this.newArray.find(qty => qty.is_primary)?.value)
          // setTimeout(() => {
          AlertService.Success(x);
          if (this.AlifeCycle == 'New' || this.AlifeCycle == 'In RFQ') {
            QuotesService.UpdateLifeCycle(this.bom_name, this.rlifeStatus).then((x) => {
              console.log(x)
              this.loading = false
              this.confirm = false;
              AlertService.Success('RFQ Submitted Successfully');
            })
            this.myStore.refreshpricing = Math.random();
            this.$emit('updateRfqStep', 2, this.rlifeStatus)
          }
          else {
            this.loading = false
            this.confirm = false
            this.myStore.refreshpricing = Math.random();
            this.$emit('updateRfqStep', 2)
          }
          // }, "1000");


        }).catch(err => {
          const errmsg = err 
          console.log("errmsg", errmsg)
          this.confirm = false
          this.loading = false
          if(typeof errmsg =='object'){
          AlertService.Error(errmsg[0].msg);}else{
            AlertService.Error(err)
          }
        })
      }

    },
    SaveClose() {
      if (this.isEditing) {
        this.closeEditing()
      }
      else {
        this.startEditing()
      }
    },
    startEditing() {
      this.isEditing = true;
      this.$emit("Editing", this.isEditing)
    },
    closeEditing() {
      this.isEditing = false
      this.$emit("Editing", this.isEditing)
      this.myStore.loadQuotes()
      this.getRfq();
    },
    AddRow(quantityAdd) {
      if (quantityAdd.length < 4 && quantityAdd.length>0 && this.isPartList==false) {
        return this.newArray.push({ value: '', is_primary: false, details: 'string' });
      }
    },
    removeArray(removeIndex) {
      var remove = this.newArray.splice(removeIndex, 1)
      console.log("remove", remove)
      return this.newArray

    },
    setAsprimary(index) {
      this.newArray.forEach((qtyVal, i) => {
        if (i === index) {
          qtyVal.is_primary = true;
        }
        else {
          qtyVal.is_primary = false
        }
      })

    },
  },
  created() {
    setTimeout(() => {
      this.getRfq();
    }, 1000)
  },
  beforeMount() {
    QuotesService.getRfqUiSchema().then(x => { this.rfqUischema = x; })
    QuotesService.getRfqSchema().then(x => { this.rfqSchema = x; });
    this.myStore.loadQuotes();
  }

})
</script>
<style scoped>
/* new style 08 */
  .filterhead{
    display: flex;
    gap: 5px;
  }
  .rfqLabel{
    background: #fff7ee !important;
    color: #6b421c !important;
  }
</style>