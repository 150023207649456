<template>
  <header class="tab-header">
    <aside class="left-menus">
      <q-btn
        @click="lifecyclestatus('Active')"
        class="left-btn mark-active"
        :disable="
          EnableOnSelection ||
          PartLibraryRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Active'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as Active
        </q-tooltip>
      </q-btn>
      <q-btn
        @click="lifecyclestatus('Obselete')"
        class="left-btn mark-obsolete"
        :disable="
          EnableOnSelection ||
          PartLibraryRows.find((row) => row.selectedRow)?.lifecycle_status ===
            'Obselete'
        "
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Mark as Obsolete
        </q-tooltip>
      </q-btn>
      <!-- <q-btn
        :disable="isRowSelected"
        @click="addtowatchlist"
        class="left-btn add-watchlist"
      >
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add to watchlist
        </q-tooltip>
      </q-btn> -->
      <q-btn disabled @click="addtowatchlist" class="left-btn add-watchlist">
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add to watchlist
        </q-tooltip>
      </q-btn>
      <button class="left-btn remove-watchlist" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Remove from watchlist
        </q-tooltip>
      </button>
    </aside>

    <aside class="search-wrap">
      <q-input
        v-model="search"
        outlined
        placeholder="Search > 3 letters"
        v-on:keyup.enter="handleEnter"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
      <section v-if="showSuggestions" class="search-suggestions">
        <!-- <p>Looking for</p> -->
        <p class="text-bold">IPN :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_ipn.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_ipn"
            :key="index"
            @click="updateSearch(item, 'ipn')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">MPN :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_mpn.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_mpn"
            :key="index"
            @click="updateSearch(item, 'mpn')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">Manufacturer :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_manufacturer.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_manufacturer"
            :key="index"
            @click="updateSearch(item, 'manufacturer')"
          >
            {{ item }}
          </li>
        </aside>
        <p class="text-bold q-mt-xs">Category :</p>
        <aside class="search-tags">
          <li v-if="autosuggest_category.length == 0" class="search_nodata">
            No suggestion found
          </li>
          <li
            v-for="(item, index) in autosuggest_category"
            :key="index"
            @click="updateSearch(item, 'category')"
          >
            {{ item }}
          </li>
        </aside>
      </section>
    </aside>

    <aside class="right-menu">
      <button class="right-btn add-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Add
        </q-tooltip>
      </button>
      <button class="right-btn update-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Updatepricing
        </q-tooltip>
      </button>
      <!-- <q-btn
        @click="deletepart()"
        class="right-btn delete-btn"
        :disable="!isRowSelected"
      > -->
      <q-btn @click="promptDeletePart()" class="right-btn delete-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Delete
        </q-tooltip>
      </q-btn>
      <q-dialog v-model="confirmDeleteDialog" persistent>
        <q-card class="bg-white">
          <q-card-section class="row items-center q-py-md">
            <q-icon name="warning" size="2.5em" color="negative" />
            <span class="q-ml-lg"
              >Are you sure you want to delete this part?</span
            >
          </q-card-section>
          <q-card-actions align="right">
            <q-btn flat label="Cancel" color="primary" v-close-popup />
            <q-btn
              flat
              label="Delete"
              color="negative"
              @click="confirmDeletion"
            />
          </q-card-actions>
        </q-card>
      </q-dialog>
      <button class="right-btn upload-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Upload
        </q-tooltip>
      </button>
      <button class="right-btn download-btn" disabled>
        <q-tooltip anchor="top middle" self="bottom middle" :offset="[10, 10]">
          Download
        </q-tooltip>
      </button>
    </aside>

    <button class="more-mob-btn">
      <q-icon name="more_vert" color="grey" class="more-mob-icon" />
      <q-menu>
        <q-list class="mob-btn-flex">
          <button class="right-btn add-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              <strong>Add</strong>
            </q-tooltip>
          </button>
          <button class="right-btn update-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Update <strong>pricing</strong>
            </q-tooltip>
          </button>
          <button class="right-btn delete-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Delete
            </q-tooltip>
          </button>
          <button class="right-btn upload-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Upload
            </q-tooltip>
          </button>
          <button class="right-btn download-btn" disabled>
            <q-tooltip
              anchor="top middle"
              self="bottom middle"
              :offset="[10, 10]"
            >
              Download
            </q-tooltip>
          </button>
        </q-list>
      </q-menu>
    </button>
  </header>

  <section class="table-wrap">
    <SpinnerComponent type="Table" v-if="loading" />
    <q-markup-table
      v-if="!loading"
      class="TableHeight-partlib my-sticky-header-table"
    >
      <thead>
        <tr>
          <th
            class="text-left"
            v-for="(col, index) in partslibraryFields"
            :key="index"
          >
            <span>{{ col.label }}</span>
          </th>
        </tr>
      </thead>
      <tbody v-for="(part, index) in data" :key="index">
        <tr class="text-left">
          <td
            class="text-left"
            v-for="(col, col_index) in partslibraryFields"
            :key="col_index"
            @click="
              col.key === 'ipn'
                ? open('right', index)
                : col.key === 'mpn'
                ? openMPNDialog('right', part)
                : null
            "
            :class="{
              'clickable-column': col.key === 'ipn' || col.key === 'mpn',
            }"
          >
            <span v-if="col.key == 'index'">
              <q-checkbox
                :model-value="isPartSelected(part)"
                size="xs"
                @update:model-value="toggleSelection(part)"
                class="q-ml-sm q-mb-md partlib"
              />
            </span>
            <span v-else-if="col.key == 'image'">
              <div class="table-img">
                <img
                  v-if="
                    part.image_url != '' &&
                    part.image_url != null &&
                    part.image_url != 'None'
                  "
                  :src="part.image_url"
                />
              </div>
            </span>
            <span v-else-if="col.key == 'mpn'">
              {{ part.mpn }}
              <q-icon
                v-if="part.is_watched_part"
                name="bookmark_added"
                class="q-ml-sm"
                size="xs"
                color="positive"
              />
            </span>
            <span v-else-if="col.key == 'description'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'category'">
              {{ part[col.key] != null ? cleanupWords(part[col.key]) : "-"
              }}<q-icon
                v-if="part[col.key] != null"
                name="more_horiz"
                class="more-icon"
              />
              <q-tooltip
                v-if="part[col.key] != null"
                anchor="bottom middle"
                self="top middle"
                :offset="[-5, -5]"
              >
                {{ part[col.key] }}
              </q-tooltip>
            </span>
            <span v-else-if="col.key == 'created_at'" class="active-td">
              <span v-if="part.lifecycle_status == 'Obselete'">
                <img src="@/assets/obsolete-icon.svg" alt="" />
                <q-tooltip
                  anchor="bottom middle"
                  self="top middle"
                  :offset="[-5, -5]"
                >
                  Obsolete
                </q-tooltip>
              </span>
              <span v-else>
                <h5>Active</h5>
                <h6>
                  {{
                    part[col.key] != null
                      ? formatActivesince(part[col.key])
                      : "-"
                  }}
                </h6>
              </span>
            </span>
            <span
              v-else-if="col.key == 'no_of_suppliers'"
              :class="{
                'link-green': part[col.key] > 2,
                'link-amber': part[col.key] <= 2,
                'link-red': part[col.key] == null || part[col.key] == 0,
                'clickable-column': col.key === 'no_of_suppliers',
              }"
              @click="open('right', index)"
              >{{ part[col.key] }} Suppliers
              <q-icon
                name="link"
                class="link-icon"
                @click="open('right', index)"
            /></span>
            <span v-else>{{ part[col.key] }}</span>
          </td>
        </tr>
      </tbody>
    </q-markup-table>

    <q-dialog
      v-model="dialog"
      class="part-alternates"
      id="home-wrap"
      persistent
      :maximized="maximizedToggle"
      @keyup.esc="dialog = false"
      @hide="clearDialogData"
    >
      <q-card class="drawer-wrap">
        <header class="part-detail-title">
          <h1>
            <q-icon name="category" class="link-icon" />

            {{ selectedPartDetails.ipn }}
          </h1>

          <q-btn dense flat icon="close" v-close-popup>
            <q-tooltip class="bg-white text-primary">Close</q-tooltip>
          </q-btn>
        </header>

        <article class="q-pa-md">
          <section class="part-detail-wrap">
            <aside class="parts-thumb">
              <img src="@/assets/parts-thumb.jpg" alt="" />
            </aside>

            <aside>
              <p>
                IPN : <span>{{ selectedPartDetails.ipn }}</span>
              </p>

              <p>
                Description : <span>{{ selectedPartDetails.description }}</span>
              </p>

              <p>
                Category : <span>{{ selectedPartDetails.category }}</span>
              </p>
              <p>
                Manufacturer :
                <span>{{ selectedPartDetails.manufacturer }}</span>
              </p>

              <p>
                Status :

                <span v-if="selectedPartDetails.lifecycle_status != null">{{
                  selectedPartDetails.lifecycle_status
                }}</span>

                <span v-else>N/A</span>
              </p>
            </aside>
          </section>

          <q-tabs
            v-model="tab"
            dense
            class="home-tab-bg q-mt-md"
            active-color="primary"
            indicator-color="purple"
            align="justify"
          >
            <q-tab name="avl" label="AVL">
              <q-badge v-if="avlTotalCount != 0" color="black" floating>{{
                avlTotalCount
              }}</q-badge>
            </q-tab>

            <q-tab name="alternateparts" label="Supplier Alternatives ">
              <q-badge v-if="aslTotalCount != 0" color="black" floating>{{
                aslTotalCount
              }}</q-badge>
            </q-tab>
          </q-tabs>

          <q-tab-panels v-model="tab" animated class="drawer-tab-panel">
            <q-tab-panel name="avl">
              <PartAlternates
                :selectedPartDetails="this.selectedPartDetails"
                @avlTotalCount="handleAvlCount"
              />
            </q-tab-panel>

            <q-tab-panel name="alternateparts">
              <SupplierAlternates
                :selectedPartDetails="this.selectedPartDetails"
                @aslTotalCount="handleAslCount"
              />
            </q-tab-panel>
          </q-tab-panels>
        </article>
      </q-card>
    </q-dialog>
    <PartAlternatesMPNDialog
      ref="partAlternatesDialog"
      :selectedPartDetails="this.selectedPartDetails"
    />
    <div v-if="PartLibraryRows.length === 0 && !loading">
      <div class="full-width row flex-center text-black q-gutter-sm">
        <q-icon size="1.5em" name="report_problem" />
        <span> No Data Found </span>
      </div>
    </div>
    <div
      class="row justify-between"
      style="border-bottom-left-radius: 20px; border-bottom-right-radius: 20px"
      v-if="!loading && PartLibraryRows.length > 0"
    >
      <div class="q-ml-lg text-grey-8 hint-fullscreen">
        <!-- <b>Tip: &nbsp;</b> Press &nbsp;<span  class="fullscreenHintText"> Shift + F</span> &nbsp; {{ toggleFullscreenTip }} -->
      </div>
      <div class="flex" style="font-size: 12px">
        <div
          class="cursor-pointer q-mr-sm q-pa-xs"
          style="font-size: 12px; margin-top: 3px"
          dense
          color="primary"
        >
          Records per page&nbsp;:&nbsp;
          <q-btn
            style="font-size: 12px"
            class="btn--no-hover q-pa-none"
            flat
            dense
            :label="
              pagination.rowsPerPage === -1 ? 'All' : pagination.rowsPerPage
            "
            icon-right="fa-solid fa-caret-down"
            color="primary"
          >
            <q-menu>
              <q-list
                dense
                v-for="(pag, index) in paginationOptions"
                :key="index"
              >
                <q-item
                  clickable
                  v-close-popup
                  @click="selectpagination(pag)"
                  class="q-pa-none"
                >
                  <q-item-section> {{ pag }}</q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </div>
        <q-pagination
          v-model="pagination.page"
          :max="pagesNumber"
          input
          class="paginationclass q-mr-sm"
          size="sm"
          direction-links
          boundary-links
          icon-first="skip_previous"
          icon-last="skip_next"
          icon-prev="fast_rewind"
          icon-next="fast_forward"
        />
      </div>
    </div>
  </section>
</template>
<script>
import { ref, defineAsyncComponent } from "vue";
import { useMyStore } from "@/store";
import { AlterPartService, AlertService } from "@/services";
import { SpinnerComponent, commonFunction } from "@/utils";
import moment from "moment";
import PartAlternatesMPNDialog from "../quotes/PartAlterNatives/PartAlternatesMPNDialog.vue";

export default {
  setup() {
    const myStore = useMyStore();
    const pagination = ref({
      sortBy: "desc",
      descending: false,
      page: 1,
      rowsPerPage: 25,
    });
    const paginationOptions = ref([5, 7, 10, 15, 20, 25, 50, "All"]);
    const dialog = ref(false);
    const position = ref("top");
    const selectedPartDetails = ref({});
    const aslData = ref([]);
    const alternatePartsData = ref([]);
    const avlTotalCount = ref("");
    const aslTotalCount = ref("");
    const showSuggestions = ref(false);
    const debounceTimeout = ref(null);
    return {
      val: ref(false),
      avlTotalCount,
      aslTotalCount,
      pagination,
      paginationOptions,
      myStore,
      search: ref("Panasonic"),
      PartLibraryRows: ref([]),
      autosuggest_ipn: ref([]),
      autosuggest_mpn: ref([]),
      autosuggest_manufacturer: ref([]),
      autosuggest_category: ref([]),
      loading: ref(false),
      dialog,
      position,
      tab: ref("avl"),
      selectedPartDetails,
      aslData,
      alternatePartsData,
      maximizedToggle: ref(true),
      showSuggestions,
      debounceTimeout,
      confirmDeleteDialog: ref(false),
      partToDelete: ref(null),
    };
  },
  components: {
    SpinnerComponent,
    PartAlternates: defineAsyncComponent(() =>
      import("@/components/quotes/PartAlterNatives/PartAlternates.vue")
    ),
    SupplierAlternates: defineAsyncComponent(() =>
      import("@/components/quotes/PartAlterNatives/SupplierAlternates.vue")
    ),
    PartAlternatesMPNDialog,
  },
  watch: {
    search(newval) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        if (newval.length >= 3) {
          if (this.filteredRows.length === 0) {
            this.partLibrary();
          }
          this.showSuggestions = true;
          this.search_auto_complete();
        } else {
          this.showSuggstions = false;
        }
      }, 500);
    },
  },
  computed: {
    partslibraryFields() {
      return this.myStore.fields.partslibrary;
    },
    pagesNumber() {
      if (this.pagination.rowsPerPage != -1 && this.search == "") {
        const newpage = Math.ceil(
          this.PartLibraryRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else if (this.pagination.rowsPerPage != -1 && this.search !== "") {
        const newpage = Math.ceil(
          this.filteredRows.length / this.pagination.rowsPerPage
        );
        return newpage;
      } else {
        return 1;
      }
    },
    data() {
      if (this.pagination.rowsPerPage == -1) {
        return this.filteredRows;
      } else {
        const startIndex =
          (this.pagination.page - 1) * this.pagination.rowsPerPage;
        const endIndex = startIndex + this.pagination.rowsPerPage;
        return this.filteredRows.slice(startIndex, endIndex);
      }
    },
    filterSuggestion1() {
      const searchTerm = this.search ? this.search.toLowerCase() : null;
      if (searchTerm !== null && searchTerm !== undefined) {
        const seen = new Set();
        return this.PartLibraryRows.filter((row) => {
          const ipnCheck =
            row.ipn && row.ipn.toLowerCase().includes(searchTerm);
          const mpnCheck =
            row.mpn && row.mpn.toLowerCase().includes(searchTerm);
          const manufacturerCheck =
            row.manufacturer &&
            row.manufacturer.toLowerCase().includes(searchTerm);
          const categoryCheck =
            row.category && row.category.toLowerCase().includes(searchTerm);
          if (
            (ipnCheck && !seen.has(row.ipn)) ||
            (mpnCheck && !seen.has(row.mpn)) ||
            (manufacturerCheck && !seen.has(row.manufacturer)) ||
            (categoryCheck && !seen.has(row.category))
          ) {
            seen.add(row.ipn);
            seen.add(row.mpn);
            seen.add(row.manufacturer);
            seen.add(row.category);
            return true;
          }

          return false;
        });
      } else {
        return [];
      }
    },
    filteredRows() {
      const searchTerm = this.search ? this.search.toLowerCase() : null;
      if (searchTerm !== null && searchTerm !== undefined) {
        return this.PartLibraryRows.filter((row) => {
          const ipnCheck =
            row.ipn && row.ipn.toLowerCase().includes(searchTerm);
          const mpnCheck =
            row.mpn && row.mpn.toLowerCase().includes(searchTerm);
          const manufacturerCheck =
            row.manufacturer &&
            row.manufacturer.toLowerCase().includes(searchTerm);
          const categoryCheck =
            row.category && row.category.toLowerCase().includes(searchTerm);

          return ipnCheck || mpnCheck || manufacturerCheck || categoryCheck;
        });
      } else {
        return [];
      }
    },
    isRowSelected() {
      if (this.PartLibraryRows.some((row) => row.selectedRow)) {
        return this.PartLibraryRows.some(
          (row) => row.selectedRow && row.is_watched_part
        );
      } else {
        return true;
      }
    },
    EnableOnSelection() {
      if (
        this.filteredRows.some((row) => row.selectedRow) &&
        this.selectedParts.length <= 1
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    handleEnter() {
      if (this.search.length >= 3) {
        this.showSuggestions = false;
      }
    },
    clearDialogData() {
      this.selectedPartDetails = {};
      this.avlTotalCount = "";
      this.aslTotalCount = "";
      this.alternatePartsData = [];
      this.dialog = false;
    },
    async open(pos, row) {
      this.position = pos;
      this.dialog = true;
      this.selectedPartDetails = this.filteredRows[row];
      this.dialog = true;
    },
    openMPNDialog(pos, item) {
      this.$refs.partAlternatesDialog.open(pos, item);
    },
    handleAvlCount(count) {
      this.avlTotalCount = count; // Update the local state with the received quoteId
    },
    handleAslCount(count) {
      this.aslTotalCount = count; // Update the local state with the received quoteId
    },
    async partLibrary() {
      this.loading = true;
      let key = "Manufacturer";
      await AlterPartService.partlibrarysearch(this.search, key)
        .then((x) => {
          this.PartLibraryRows = x;
          this.PartLibraryRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PartLibraryRows = [];
          this.loading = false;
        });
    },
    async search_auto_complete() {
      await AlterPartService.autocomplete(this.search)
        .then((x) => {
          this.autosuggest_ipn = x.ipn;
          this.autosuggest_mpn = x.mpn;
          this.autosuggest_manufacturer = x.manufacturer;
          this.autosuggest_category = x.category;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async addtowatchlist() {
      //add to  watch list
      const result = {
        parts: this.selectedParts.map((part) => ({
          ipn: part.ipn,
          manufacturer: part.manufacturer,
          mpn: part.mpn,
        })),
      };

      if (result) {
        // const { ipn, mpn, manufacturer } = result;
        const firstTag = this.myStore.userdata.username;
        await AlterPartService.addwatchlist(firstTag, result)
          .then(() => {
            AlertService.Success("Added to Watchlist");
            this.partLibrary();
          })
          .catch((err) => {
            AlertService.Error(err);
          });
      } else {
        AlertService.Error("Please Select one row to add watchlist");
      }
    },
    promptDeletePart() {
      // delete parts from partlibrary
      const result = this.filteredRows.find((row) => row.selectedRow === true);
      if (!result) {
        AlertService.Error(
          "Please Select one row to delete parts from partlibrary"
        );
        return;
      }
      this.partToDelete = result;
      this.confirmDeleteDialog = true;
    },
    confirmDeletion() {
      if (this.partToDelete) {
        this.deletepart(this.partToDelete);
        this.confirmDeleteDialog = false;
        this.partToDelete = null;
      }
    },
    async deletepart(result) {
      const { ipn, mpn, manufacturer } = result;
      AlterPartService.deletepartlibrary(ipn, mpn, manufacturer)
        .then((response) => {
          AlertService.Success(response);
          this.partLibrary();
        })
        .catch((error) => {
          AlertService.Error("Failed to delete this part: " + error.message);
        });
    },
    cleanupWords(description) {
      const words = commonFunction.descriptionFormat(description);
      return words;
    },
    formatActivesince(date) {
      const fdate = moment(date).format("YYYY/MM/DD");
      return fdate;
    },
    selectpagination(value) {
      //Changing the Pagination variable
      if (value == "All") {
        this.pagination.rowsPerPage = -1;
      } else {
        this.pagination.rowsPerPage = parseInt(value);
      }
      this.pagination.page = 1;
    },
    async updateSearch(options, key) {
      //part library from auto suggestion
      this.search = options;
      this.showSuggestions = false;
      await AlterPartService.partlibrarysearch(this.search, key)
        .then((x) => {
          this.PartLibraryRows = x;
          this.PartLibraryRows.map((row, index) => {
            row.selectedRow = false;
            row.ipnIndex = row.ipn + index;
            row.rowIndex = index;
          });
        })
        .catch((err) => {
          this.PartLibraryRows = [];
          console.log(err);
        });
    },
    async lifecyclestatus(status) {
      //Update the life cycle status
      const result = this.PartLibraryRows.find(
        (row) => row.selectedRow === true
      );
      if (result) {
        const { ipn, mpn, manufacturer } = result;
        await AlterPartService.updatepartlifecycle(
          ipn,
          mpn,
          manufacturer,
          status
        )
          .then((x) => {
            AlertService.Success(x);
            this.selectedParts = [];
            this.partLibrary();
          })
          .catch((err) => {
            AlertService.Error(err);
          });
      } else {
        AlertService.Error("Please Select one row to update  Status");
      }
    },
    isPartSelected(part) {
      return this.selectedParts.includes(part);
    },
    toggleSelection(part) {
      if (this.isPartSelected(part)) {
        this.PartLibraryRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts = this.selectedParts.filter(
          (selectedPart) => selectedPart !== part
        );
      } else {
        this.PartLibraryRows.forEach((data) => {
          if (data.ipnIndex == part.ipnIndex) {
            data.selectedRow = !data.selectedRow;
          }
        });
        this.selectedParts.push(part);
      }
    },
  },
  data() {
    return {
      selectedParts: [], // Array to keep track of selected rows
    };
  },
  mounted() {
    this.partLibrary();
    this.search_auto_complete();
  },
};
</script>
<style scoped lang="scss">
.link-icon {
  cursor: pointer;
}

.drawer-wrap {
  //width: 1500px;
  //height: 100%;
  background-color: #fff;
  //padding: 15px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    color: #3d4050;
    line-height: normal;
    margin: 0;
    // border-bottom: solid 1px #e9e9e9;
    // padding-bottom: 10px;
    // padding-left: 15px;
    // margin-left: -15px;
    // margin-right: -15px;
  }
}

.part-detail-wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 15px;
  // margin-top: 15px;

  p {
    font-size: 13px;
    color: #777984;
    span {
      color: #3d4050;
    }
  }
}

.parts-thumb {
  img {
    width: 230px;
    height: 165px;
    object-fit: cover;
    border-radius: 10px;
  }
}

.trend-chart-wrap {
  border-radius: 10px;
  margin-top: 15px;
  background-color: #f6f6f7;
  border: solid 1px #e9e9e9;
  padding: 15px;

  h2 {
    color: #3d4050;
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 15px;
  }
}

.drawer-tab-panel {
  border: solid 1px #e9e9e9 !important;
  padding: 15px;
  border-radius: 0px 0px 10px 10px !important;
}

.part-detail-title {
  border-bottom: solid 1px #e9e9e9;
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
}

#home-wrap {
  padding: 0px;
}

.clickable-column {
  cursor: pointer;
}
</style>
