<template>
  <main id="home-wrap">
    <q-tabs
      v-model="tab"
      class="home-tab-bg"
      active-color="primary"
      indicator-color="purple"
      align="justify"
    >
      <q-tab name="parts" label="Parts Library" icon="category" />
      <q-tab
        name="watchlist"
        label="Watchlist"
        icon="bookmark"
        :disable="isWatchlistDisabled"
      />
    </q-tabs>

    <q-tab-panels v-model="tab" animated class="home-tab-panel">
      <q-tab-panel name="parts">
        <PartLibrary />
      </q-tab-panel>

      <q-tab-panel name="watchlist">
        <WatchListTags />
      </q-tab-panel>
    </q-tab-panels>
  </main>
</template>

<script>
import { ref, onMounted } from "vue";
import PartLibrary from "@/components/home/PartLibrary.vue";
import WatchListTags from "@/components/home/WatchListTags.vue";

export default {
  setup() {
    const tab = ref("parts");
    const isWatchlistDisabled = ref(true);

    onMounted(() => {
      const savedTab = localStorage.getItem("activeTab");
      const fromNotifications = localStorage.getItem("fromNotifications");
      if (savedTab === "watchlist" && fromNotifications === "true") {
        tab.value = "watchlist";
        localStorage.removeItem("fromNotifications");
      } else {
        tab.value = "parts";
      }
    });
    return {
      tab,
      isWatchlistDisabled,
    };
  },
  components: {
    PartLibrary,
    WatchListTags,
  },
};
</script>
